import React from 'react';
// import Header from './Header';
import { setUserSession } from './Utils/Common';
import {useHistory } from 'react-router-dom';
// import { useCookies } from 'react-cookie';

function Savetoken() {

  const history = useHistory();
  // const [cookies, setCookie] = useCookies(['user','token']);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    let user = params.get('user')
    console.log('save new token ',token,user)
    // setCookie('token', token);
    // setCookie('user', user);
    setUserSession(token,user);
    const timeout = setTimeout(() => {
      history.push('/market');
     }, 500);
     return () => clearTimeout(timeout);
  }, [])

  // console.log('Savetoken: ')

  return (
    <>
      {/* <Header/> */}
      {/* <a href='http://localhost:3000/dashboard'>Перейти http://localhost:3000/dashboard</a> */}
      <div>Зачекайте...</div>
    </>
  );
}

export default Savetoken;
