import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';
// import { useCookies} from 'react-cookie';

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  // const [cookies] = useCookies();
  // console.log('PublicRoute',cookies.token)

  return (
    <Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/market' }} />}
    />
  )
}

export default PublicRoute;