import React from 'react';
import { Grid,Button,Image, ButtonGroup,Avatar, Link, useMediaQuery, Popover,Text,Divider } from '@geist-ui/core'
import { getUser,getToken, removeUserSession } from './Utils/Common';
// import MoreVertical from '@geist-ui/react-icons/moreVertical'
import { NavLink,useHistory } from 'react-router-dom';
// import { Menu } from '@geist-ui/icons'
// import ChevronDown from '@geist-ui/icons/chevronDown'
import robot from './img/robot.png';
import logo_green from './img/logo_green.png';
import logo_black from './img/logo_black.png';

import { UserX } from '@geist-ui/icons'
import Usage from './Usage';




let test = {
  // color:'#333',
  // 'border':'1px solid red'
}

export default function Header() {
  
  const history = useHistory();
  const user = getUser();

  // const token = getToken();
  const isXS = useMediaQuery('xs')
  
  const handleLogout = () => {
    removeUserSession()
    history.push(`/register`);
  }
  
  

  const cabinet = () => (
    <div>
      <Popover.Item title>
        <span>{user}</span>
      </Popover.Item>
      <Popover.Item>
          <NavLink activeClassName="active" to="/market" >
            Інформація про ринок
          </NavLink>
      </Popover.Item>
      <Popover.Item>
          <NavLink activeClassName="active" to="/docs" >
            Інформація про документацію
          </NavLink>
      </Popover.Item>
      <Popover.Item>
          <NavLink  to="/api" >Наше API</NavLink>
      </Popover.Item>
      
      <Divider />
      <Popover.Item>
        <Grid.Container justify="center" direction="row" alignItems="center"
        >
          <Button type="error" onClick={handleLogout} ghost scale={0.7} icon={<UserX />}>Вийти</Button> 
        </Grid.Container>
      </Popover.Item>
    </div>
  )

  

// console.log('HEADER:',user,token)

  return (

    // <Image width="100%" height="260px" src={cover} />

    <Grid.Container justify="center" direction="row" 
      alignItems="center"
      style={{padding:'10px',borderBottom:'1px solid #bfd3e6'}}
      type="dark"
      >
      
        {user&&user!='null'?
        <>
      
          <Grid xs={12} sm={4} md={4} style={test}>
              <NavLink  to="/" >
                <Image width={"40px"} src={robot} />
              </NavLink>
              <Image width={"100px"} src={logo_green} />
          </Grid>
          
          <Grid xs={6} sm={16} md={17} style={test}>
          </Grid>
          
          <Grid xs={6} sm={4} md={2} style={test}>
            <Popover content={cabinet} placement="bottomEnd">
              <Link href="#">
              <Avatar scale={2} text={user.slice(0,3)} />
              </Link>
            </Popover>
          </Grid>
          
         
         
          
        </>:
        
        
        <>
 
          <Grid xs={12} sm={4} md={4} style={test}>
                <NavLink  to="/" >
                  <Image width={"40px"} src={robot} />
                </NavLink>
                <Image width={"100px"} src={logo_green} />
          </Grid>
          <Grid xs={0} sm={16} md={6} style={test}>
            <Text small style={{color:'grey',size:10}}>
              Інформація про ринок закупівель і тендерну документацію людською мовою
            </Text>
          </Grid>
          <Grid xs={6} sm={0} md={11} style={test}>
          </Grid>
        
          <Grid xs={6} sm={4} md={2} style={test}>
             <NavLink activeClassName="active" to="/register" >
                <Button style={{textTransform: 'uppercase',fontFamily:"'Roboto', sans-serif"}} type="secondary" auto >Увійти</Button>
              </NavLink>
          </Grid>
          
        </>
            

          
              }    
          
        
      </Grid.Container>

  );

}
