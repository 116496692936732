import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import axios from 'axios';
import './css/App.css';

import Footer from './Footer';
import Ugoda from './Ugoda';

import Savetoken from './Savetoken';
import Register from './Register';
import Error from './Error';
import Chat from './Chat';
import Docs from './Docs';
import Home from './Home';
import API from './API';


// import "@fontsource/roboto"; // Defaults to weight 400.

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';

import { getToken, getUser,removeUserSession, setUserSession } from './Utils/Common';
import { useCookies} from 'react-cookie';


function App() {
  
  useEffect(() => {
    
    const user = getUser();
    const token = getToken();
  
    // console.log('APP:',user,token)

    axios.get(`https://chat-to-tender.vercel.app/api/verify`,{
      headers: {
        'Authorization': `${token}`
      }
    }
    )
    .then(response => {
      // console.log('response',response)
     }).catch(error => {
      removeUserSession();
      // removeCookie(['user']);
      // removeCookie(['token']);
    });
  }, []);
  
  

  // if (authLoading && getToken()) {
  //   return <Spinner />
  // }

  return (
    <div className="App">


      <BrowserRouter>
      {/* <Header/> */}
      <div className="content">
            <Switch>
              
              <Route exact path="/" component={Home} />
              <Route path="/savetoken" component={Savetoken} />
              <Route path="/ugoda" component={Ugoda} />
              <PublicRoute path="/register" component={Register} />
              {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
              <PrivateRoute path="/market" component={Chat} />
              <PrivateRoute path="/docs" component={Docs} />
              <PrivateRoute path="/api" component={API} />
              <Route path='/*' component={Error}/>
              {/* <PrivateRoute path="/api" component={API} /> */}
            </Switch>
          </div>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
