import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<CookiesProvider defaultSetOptions={{ path: '/' }}>
  <GoogleOAuthProvider clientId="179716064755-jpfv6upcjds0ievi88meukvsgpg0l9oe.apps.googleusercontent.com">
        <React.StrictMode>
        <App />
        </React.StrictMode>
  </GoogleOAuthProvider>
  </CookiesProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
