import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
// import Header from './Header';
import { Grid,Link,Card,Image,Spacer,Text,Divider} from '@geist-ui/core'
import parnters from './img/partners2.png';


let test = {
  // border:"1px solid grey",
  color:'grey'
}


function Footer() {


  return (
    <div>
      {/* <Spacer h={2}/> */}
      {/* <Divider/> */}

      <Grid.Container justify="center" alignItems="center"  style={{background:'#eee', borderTop:"1px solid #ddd"}}>
          
          
          <Grid xs={22} sm={22} md={6} direction="column" style={{marginTop:'20px',padding:'0px'}}>
            <Text small>
             Центр вдосконалення закупівель Kyiv School of Economics.  
             Київ, вул. Шпака, 3, Email: cep@kse.org.ua
            </Text>
            {/* <Spacer h={1}/> */}
           
          </Grid>
          <Grid xs={0} sm={2} md={2} direction="column" style={{marginTop:'0px',padding:'0px'}}>
          
          </Grid>
          <Grid xs={22} sm={22} md={10} direction="row" style={{marginTop:'20px',padding:'0px'}}>
          <Text small style={{color:'grey'}}>Інші проекти СЕР:</Text>
            <Spacer w={2}/>
            <Text small><Link underline href="https://cep.kse.ua/">Сайт cep.kse.ua</Link></Text><Spacer w={1}/>
            <Text small><Link underline href="https://cpvtool.kse.ua/">CPV-tool</Link></Text><Spacer w={1}/>
            <Text small><Link underline href="https://cepjobs.kse.ua/">CEPJobs</Link></Text><Spacer w={1}/>
            <Text small><Link underline href="https://cep.kse.ua/osoba">Уповноважена особа</Link>
            </Text>    
            
          </Grid>
          
          {/* <Grid xs={22} sm={7} md={5} direction="column" style={{marginTop:'20px',padding:'10px'}}>
            <Text b small>Інші проекти СЕР</Text>
            <Text small><Link underline href="https://cep.kse.ua/">Сайт cep.kse.ua</Link></Text>
            <Text small><Link underline href="https://cpvtool.kse.ua/">CPV-tool</Link></Text>
            <Text small><Link underline href="https://cep.kse.ua/osoba">Уповноважена особа</Link>
            </Text>    
          </Grid> */}
          
           
        <Spacer h={6}/>
        
        </Grid.Container>
      
     

    </div>
  );
}

export default Footer;
