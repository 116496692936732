// return the user data from the session storage
export const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user2'))
  // console.log('!!!!!!!! user',user)
  return user
  // if (userStr) return JSON.parse(userStr);
  // else return null;
}

// return the token from the session storage
export const getToken = () => {
  const token = localStorage.getItem('token2');
  // console.log('!!!!!!!! token',token)
  return token
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token2');
  localStorage.removeItem('user2');
  
  // localStorage.removeItem('paid');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem('token2', token);
  localStorage.setItem('user2', user);
  // const items = { ...localStorage };
  // console.log('!',items)
  // localStorage.setItem('paid', JSON.stringify(paid));
}