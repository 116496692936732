import React from 'react';
import { getUser,getToken } from './Utils/Common';
import { Grid,Card,Button,Snippet,ButtonDropdown,Note,useMediaQuery,Loading,Text,Spacer,Image,Table,Textarea,Modal,useModal,useTheme,Link,Divider } from '@geist-ui/core'
import axios from 'axios';
// import Header from './Header';
import { NavLink,useParams } from 'react-router-dom';
import * as d3 from "d3";
import * as Plot from "@observablehq/plot";

// import Usage from './Usage';
import Dashboard from './Dashboard';
// import API from './API';
import robot from './img/robot.png';
import Send from '@geist-ui/icons/send'
import { ArrowLeft } from '@geist-ui/icons'
import { ArrowRight } from '@geist-ui/icons'
import Copy from '@geist-ui/icons/copy'

import bipro from './img/bipro.png';

// import queryString from 'query-string';

// import ArrowDown from '@geist-ui/icons/arrowDown'
// import ExternalLink from '@geist-ui/icons/externalLink'
// import Trash2 from '@geist-ui/icons/trash2'
// import { NavLink } from 'react-router-dom';


let test = {
  // 'border':'1px solid red'
}

function Chat() {
  const user = getUser();
  // const token = getToken();
  const { palette } = useTheme()

  const isXS = useMediaQuery('xs')
  const [open, setOpen] = React.useState(0)


  
  const containerRef = React.useRef();
  const containerRef2 = React.useRef();

  const [loadingBi, setLoadingBi] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [question, setQuestion] = React.useState("")
  const [answer, setAnswer] = React.useState(null)
  const [context, setContext] = React.useState(null)
  const [usagetender, setUsagetender] = React.useState(0)
  const [stats, setStats] = React.useState(null)

  const [message, setMessage] = React.useState("Перетворюю питання в запит")

  const { setVisible, bindings } = useModal()

  
  
  const handleSubmitQa = event => {
    // event.preventDefault()
    setLoading(true)
    setAnswer(null)
    setContext(null)
    getContext()
    const timer = setTimeout(() => setMessage("Запит з бази даних"), 12000);
    return () => clearTimeout(timer);
  }
  

  React.useEffect(() => {
    try {
      axios.get(`https://api-chat-to-tender.vercel.app/api/chatbi`)
      .then(response => {
        setLoadingBi(false)
        setStats(response.data.datemod)
        // console.log(response.data)
        })   
    } catch (error) {
      // setSpinner(false)
    }
  }, []);
  
  
  
  const getContext = () => {
    try {
      axios.get(`https://api-chat-to-tender.vercel.app/api/chatsql?question=${question}&user=${user}`)
      .then(response => {
        // console.log('response.data 1') 
        setMessage("Описую дані")
        return response.data
       })
       .then(response => {
          getContext2(response)
       })
       .catch((error) =>{
        setAnswer('Нажаль, чат неспрацював :( Спробуйте ще раз _ ')
       })
    } catch (error) {
      setAnswer('Нажаль, чат неспрацював. Спробуйте ще раз _ ')
    }
  }
  
 
 
 
  const getContext2 = (data) => {
    try {
      axios.post(`https://api-chat-to-tender.vercel.app/api/chatsql2?user=${user}`, data)      
      .then(response => {
        // console.log('response.data 2') 
        
        if (response.data.context){
          setLoading(false)
          setContext(response.data.context)
          setAnswer(response.data.answer)
          setMessage("Перетворюю питання в запит до бази даних")
          // console.log(d3.csvParse(response.data.context).columns)
        }else{
          setAnswer(response.data.answer+" Для таких параметрів пошуку, я не отримав жодних результатів")
          setLoading(false)
          setMessage("Перетворюю питання в запит до бази даних")
        }
       })
       .catch((error) =>{
        setAnswer('Нажаль, чат неспрацював :( Спробуйте ще раз _ ')
       })
    } catch (error) {
      setAnswer('Нажаль, чат неспрацював. Спробуйте ще раз _ ')
    }
  }
  
  
  
  const renderRound = (value, rowData, index) => {
    //  console.log('rowData',rowData)
     if(+value){
     return <Text>{(+value).toFixed(1)}</Text>  
     }else{
      return <Text>{value}</Text>  
     }   
  }
  
  
  
  

  React.useEffect(() => {
    if (!context || d3.csvParse(context).length<2) return;
    // if (!answer) return;
    console.log('!',d3.csvParse(context))
    let columns = d3.csvParse(context).columns
    let swidth = screen.width;
    
    const plot = Plot.plot({
      style:{background:'#e0ecf4'},
      marginLeft: 150,
      marginBottom:30,
      height:d3.csvParse(context).length*50,
      width:swidth>650?650:350,
      x: { tickFormat: (d) => d + "", label: columns[1],grid:true },
      y: { label: null },
      marks: [
        Plot.axisY({lineWidth:14}),
        Plot.barX(d3.csvParse(context), { x: columns[1], y: columns[0],fill:"#8856a7",sort:{y:'-x'} })
      ]
    });
    containerRef.current.append(plot);
    return () => plot.remove();
  }, [context]);
  
  
  
  React.useEffect(() => {
    if (!context || d3.csvParse(context).length<2) return;
    // if (!answer) return;
    let columns = d3.csvParse(context).columns
    if(columns.length<=2)return 
    // console.log('!!',columns.length)
    let swidth = screen.width;
    
    const plot2 = Plot.plot({
      style:{background:'#e0ecf4'},
      marginLeft: 150,
      marginBottom:30,
      height:d3.csvParse(context).length*50,
      width:swidth>650?650:350,
      x: { tickFormat: (d) => d + "", label: columns[2],grid:true},
      y: { label: null },
      marks: [
        Plot.axisY({lineWidth:14}),
        Plot.barX(d3.csvParse(context), { x: columns[2], y: columns[0],fill:"#8856a7",sort:{y:'-x'} })
      ]
    });
    containerRef2.current.append(plot2);
    return () => plot2.remove();
  }, [context]);
  
  
  
  
  // console.log('Chat:',context)




  return (
    <>
    
      {isXS&&
        <div style={{position:'absolute',top:'4%',left:'1%'}}>
          {open>0?<ArrowLeft color="#fff" className="hover" onClick={()=>setOpen(0)}/>:
          <ArrowRight className="hover" onClick={()=>setOpen(24)}/>}
        </div>}
     
     
     
     
     
      <Grid.Container justify="center">
        <Grid xs={open} sm={8} md={5} direction="column" style={{background:'#111'}}>    
          <Dashboard />
        </Grid>
        
       
        
        <Grid xs={24-open} sm={16} md={19} direction="column" 
       style={{background:'#fff'}}
        // style={{height:'100vh',border:'1px solid red'}}
        >  
  
      <div style={{
       "background": "rgb(21,122,220)",
       "background": "radial-gradient(circle, rgba(21,122,220,.7) 0%, rgba(63,212,155,1) 100%)"       
}}>
      <Spacer h={1}/>
          <Grid.Container justify="center">
          <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
          </Grid>
            <Grid xs={10} sm={12} md={18} style={test} direction="row" justify="center"> 
              <ButtonDropdown type="secondary">
                <ButtonDropdown.Item main><NavLink to="/market" ><Text style={{color:'#fff'}}>Про ринок</Text></NavLink></ButtonDropdown.Item>
                <ButtonDropdown.Item><NavLink to="/docs" ><Text style={{color:'#000'}}>Робота з документами</Text></NavLink></ButtonDropdown.Item>
                <ButtonDropdown.Item><NavLink to="/api" ><Text style={{color:'#000'}}>Документи по API</Text></NavLink></ButtonDropdown.Item>
              </ButtonDropdown>
            </Grid>
            <Grid xs={23} sm={23} md={4} justify="center" direction="column">    
            </Grid>
          </Grid.Container>
      <Spacer h={1}/>

      
      <Grid.Container  justify="center" style={test}>
        <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
        </Grid>
        
          <Grid xs={23} sm={23} md={18} style={test} direction="column">  
          
           
            
              <Text style={{color:'#4c2889'}}>
              Інфомація про всі контракти з Prozorro. Задавайте питання про ринки, замовників, постачальників і т. п. Наприклад:
              </Text>
              
              <Grid.Container gap={1} justify="center" style={{minHeight:"100px"}}>
                <Grid xs={23} sm={23} md={12}><Text small style={{color:'#4c2889', border:'1px solid #4c2889',padding:"5px",margin:'5px',borderRadius: "5px"}}>Назви 3 найбільших постачальників пального замовникам з Рівненської області за сумою угод і вкажи кількість контрактів </Text> </Grid>
                <Grid xs={23} sm={23} md={12}><Text small style={{color:'#4c2889', border:'1px solid #4c2889',padding:"5px",margin:'5px',borderRadius: "5px"}}>Порівняй між собою середню економію в різних замовників в яких в назві є слово Служба відновлення</Text></Grid>
               </Grid.Container>
               
               <Grid.Container gap={1} justify="center" style={{minHeight:"100px"}}>
                <Grid xs={23} sm={23} md={12}><Text small style={{color:'#4c2889', border:'1px solid #4c2889',padding:"5px",margin:'5px',borderRadius: "5px"}}>Порахуй кількість і суми всіх закупівель Укрпошта в розрізі різних методів закупівлі ("Відкриті торги з особливостями", "Запит ціни пропозиції" і т.д.)</Text> </Grid>
                <Grid xs={23} sm={23} md={12}><Text small style={{color:'#4c2889', border:'1px solid #4c2889',padding:"5px",margin:'5px',borderRadius: "5px"}}>Скільки було тендерів, на яку суму, яка середня конкуренція і економія, на ринку молока за останні 3 місяці?</Text></Grid>
               </Grid.Container>

      </Grid>    
        <Grid xs={23} sm={23} md={4} justify="center" direction="column">    
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      
    
      
      <Grid.Container  justify="center" style={test}>
        <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
        </Grid>
        
        <Grid xs={23} sm={23} md={18} style={test} direction="column">  
        {/* <Text onClick={() => setVisible(true)}>Правила і поради щодо пошуку</Text> */}
          <Link href="#" icon underline onClick={() => setVisible(true)}>
          <Text small style={{color:'#4c2889'}}>Правила і поради щодо пошуку</Text>
          </Link>
        </Grid>    
        <Grid xs={23} sm={23} md={4} justify="center" direction="column">    
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      </div>
      
      
      
      <Modal width="50rem" {...bindings}>
        {/* <Modal.Title>Modal</Modal.Title> */}
        <Modal.Subtitle>Правила і поради щодо пошуку</Modal.Subtitle>
        <Modal.Content>
        <Note type="success">Інформація доступна за 2023-2024 роки. За замовчуванням пошук здійснюється лише по тендерам, тобто конкурентним закупівлям. Якщо вам терба інформація по всім без виключення контрактам (включаючи звітування), тоді про це треба вказати явно, наприклад "...включно із звітами" або "всі закупівлі" і т. п.</Note>
        <Spacer h={1}/>
        <Text small>Опис полів по яким доступна інформація:</Text>
        <br></br>
        <Spacer h={1}/>
             ● <Text small>Ви можете обирати контракти по даті, вказуючи місяць, проміжок часу і т. д.</Text><br></br>
             ● <Text small>По назві замовника/організатора або коду ЄДРПОУ</Text><br></br>
             ● <Text small>По назві продавця/учасника/переможця або коду ЄДРПОУ</Text><br></br>
             ● <Text small>По області (регіону) місцезнаходження замовника чи постачальника/учасника</Text><br></br>
             ● <Text small>Шукати окремо по неконкурентним, конкурентним чи закупівлям в маркеті</Text><br></br>
             ● <Text small>cpv-коду</Text><br></br>
             ● <Text small>Кількісті учасників, дискваліфікацій чи економії (вказати порахувати середню, діапазон і т. п.)</Text><br></br>
             ● <Text small>Очікуваною вартістю чи сумою договорів</Text><br></br>
             ● <Text small>Процедурі/типу методу закупівлі: "Відкриті торги з особливостями", "Запит ціни пропозиції" та інші</Text><br></br>
             
             <Spacer h={2}/>
             
        <Divider />
            
        <Spacer h={2}/>
        
        <Text small>
          В цілому результати співпадають із даними модуля аналітики https://bi.prozorro.org/ за деякими несуттєвими відмінностями (різне врахування дат, статусу контрактів і т. п.). Для більш точної і системної аналітики ми радимо користуватися bi.prozorro. 
        </Text>
        
        <Image width="100%"  src={bipro} />
             
        </Modal.Content>
        {/* <Modal.Action passive onClick={() => setState(false)}>Cancel</Modal.Action>
        <Modal.Action>Submit</Modal.Action> */}
      </Modal>
      
      
      
      
      
      
      
      <Spacer h={1}/>
      
      
      
      
      
      <Grid.Container  justify="center">
        <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
        </Grid>
        <Grid xs={23} sm={23} md={18} style={test}>  
         {loadingBi&& <Spacer h={1}/>}
         {stats&&<Text small style={{color:'#ccc',fontSize:"12px"}}>Дані за 2023-2024 роки. Останнє оновлення {stats}</Text>}
         </Grid>
         <Grid xs={23} sm={23} md={4} justify="center" direction="column">    
          </Grid>
       </Grid.Container>
       
       
       <Spacer h={2}/>
       
       
       
       
       
      {usagetender>=0&&
      <>
      <Grid.Container  justify="center" style={test}>
          <Grid xs={0} sm={4} md={2} style={test}>    
          </Grid>
          <Grid xs={18} sm={18} md={18} justify="center" direction="row" style={test}
           alignItems="center"
           >
          {/* <form onSubmit={handleSubmitQa}> */}
            <Textarea resize="vertical" type="secondary" style={{color: "#333",height:"50px",fontSize:'14px'}} value={question} onChange={(e)=>setQuestion(e.target.value)} placeholder="Питайте будь що: про ринок, замовників, постачальників" width="100%"/>
          {/* </form> */}
        </Grid>
        <Grid xs={.5} sm={.5} md={.5} justify="center" direction="column">
        </Grid>
        <Grid xs={4} sm={6} md={1.5} justify="center" direction="column">
          <Button style={test} iconRight={<Send />} onClick={handleSubmitQa} auto  type="secondary" scale={.75} disabled={question==""?true:false}/>
        </Grid>
        <Grid xs={0} sm={23} md={2} justify="center" direction="column">
        </Grid>
      </Grid.Container>
      
      
      <Spacer h={3}/>
      
      
     
      <Grid.Container  justify="center" style={test}>
        <Grid xs={23} sm={4} md={2} justify="center" direction="column" style={test}>
          {(loading||answer)&&<Image width={"40px"} src={robot} />}
        </Grid>
        
        <Grid xs={23} sm={20} md={18} justify="center" direction="column" style={test}>
          
            {answer&&<Card width="100%" style={{paddingRight:'30px',border:'1px solid #4c2889',background:'#e0ecf4'}}>
            <Text style={{color:'#4c2889'}}>{answer}</Text>
            
            <Spacer h={2}/>
            
            <div style={{"overflow": "auto" }}>
            {context&&<Table hover={false} data={d3.csvParse(context).map(({ id,date,datemod,datemodified, ...item }) => item)}>
              {d3.csvParse(context).columns.filter(d=>d!='id'&&d!='datemodified'&&d!='datemod'&&d!='date')
                .map((d,i) => (
                  <Table.Column key={i} prop={d} label={d} render={renderRound}/>
                ))}
            </Table>}
            </div>
            
            <Divider h={2} style={{background:'#fff'}}/>
            
            <Spacer h={2}/>
               
            <div ref={containerRef} />
            <Spacer h={1}/>
            <div ref={containerRef2} />
           
          
            </Card>}
            
            {loading&&<Card width="100%" style={{paddingRight:'40px',paddingTop:'20px',paddingBottom:'20px',border:'1px solid #4c2889',background:'#e0ecf4'}}><Loading color='#4c2889'><Text style={{color:'#4c2889'}}>{message}</Text></Loading></Card>}
                    
        </Grid>
        
        <Grid xs={23} sm={23} md={4} justify="center" direction="column">    
        </Grid>
        
      </Grid.Container>
      
      </> }
      
            
      <Spacer h={6}/> 


        </Grid>
        
        
      </Grid.Container>
      
      
  
      
      
    </>
  );
}



export default Chat;
