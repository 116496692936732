import React from 'react';
import { getUser,getToken } from './Utils/Common';
import { Grid,Card,Spinner, Loading,Text,Spacer,Fieldset,Tabs,Table,Input } from '@geist-ui/core'
import axios from 'axios';
import * as Plot from "@observablehq/plot";


let test = {
  border:"1px solid #eee"
}



function Usage() {
  const user = getUser();
  const token = getToken();
  const containerRef = React.useRef();
  const containerRef2 = React.useRef();
  const [usagetender, setUsagetender] = React.useState('')

  const [tender, setTender] = React.useState([])
  const [question, setQuestion] = React.useState([])
  

  React.useEffect(() => {
    try {
      axios.get(`https://chat-to-tender.vercel.app/api/usage_question?user=${user}`)
      .then(response => {
        setUsagetender(response.data.count)
      })   
    } catch (error) {}
  }, []);
  
  
  
  // React.useEffect(() => {
  //   try {
  //     axios.get(`https://chat-to-tender.vercel.app/api/usage_tender_by_day?user=${user}`)
  //     .then(response => {
  //       setTender(response.data.map(d=>{
  //         return {
  //           ...d,
  //           date:new Date(d._id),
  //           count:d.count            
  //         }}))
  //       })   
  //   } catch (error) {
  //     // setSpinner(false)
  //   }
  // }, []);
  
  React.useEffect(() => {
    try {
      axios.get(`https://chat-to-tender.vercel.app/api/usage_question_by_day?user=${user}`)
      .then(response => {
        setQuestion(response.data.map(d=>{
          return {
            ...d,
            date:new Date(d._id),
            count:d.count           
          }}))
        })   
    } catch (error) {
      // setSpinner(false)
    }
  }, []);
  
  
  let start = new Date()
  let end = new Date()
  start = new Date(start.getFullYear(), start.getMonth(), -1)
  end = new Date(end.setMonth(end.getMonth() + 1))
  
  // React.useEffect(() => {
  //   if (tender === undefined) return;
  //   const plot = Plot.plot({
  //   style:{fontSize:14,background:"#e0ecf4"},
  //   grid:true,
  //   height:350,
  //   margin:50,
  //     y: {grid: true,label:'Статистика тендерів'},
  //     x:{domain:[start,end]},
  //     marks: [
  //       Plot.frame(),
  //       Plot.rectY(
  //         tender,
  //         Plot.binX(
  //           { y: "sum" },
  //           { x: "date", y: "count", fill: "blue", interval: "day" }
  //         )
  //       )
  //     ]
  //   });
  //   containerRef.current.append(plot);
  //   return () => plot.remove();
  // }, [tender]);
  
  
  
  React.useEffect(() => {
    if (question === undefined) return;
    const plot = Plot.plot({
    style:{fontSize:14,background:"#edf8fb"},
    grid:true,
    height:350,
    margin:50,
      y: {grid: true,label:'Статистика питань'},
      x:{domain:[start,end]},
      marks: [
        // Plot.frame(),
        Plot.ruleY([30],{stroke:'red'}),
        Plot.rectY(
          question,
          Plot.binX(
            { y: "sum" },
            { x: "date", y: "count", fill: "navy", interval: "day" }
          )
        )
      ]
    });
    containerRef2.current.append(plot);
    return () => plot.remove();
  }, [question]);
  


  // console.log('Usage:',start,end,tender)


  return (
    <>
      {/* <div ref={containerRef} /> */}
      <Text b>Цього місяця ви питали/отримували відповідь {usagetender} рази/разів</Text>     
      <Spacer h={1}/>
      <div ref={containerRef2} />
    </>
  );
}



export default Usage;
