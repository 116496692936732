import React, { useState } from 'react';
import axios from 'axios';
// import { setUserSession } from './Utils/Common';
import { Divider,Grid,Input,Button,Spacer,useTheme,Text,Link } from '@geist-ui/core'
// import Googlelog from './Googlelog';
// import { GoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
// import { GoogleLoginButton } from "react-social-login-buttons";
import {useHistory } from 'react-router-dom';
import {setUserSession } from './Utils/Common';
import Header from './Header';
import { jwtDecode } from "jwt-decode";

function Register() {

  const history = useHistory();
  const theme = useTheme()
  // const [state, setState] = useState(false)
  // const handler = () => setState(true)
  // const closeHandler = (event) => {
  //   setState(false)
  //   console.log('closed')
  // }
  
    // const googleLogin = useGoogleLogin({
    //     onSuccess: (codeResponse) => {
    //       axios
    //         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
    //             headers: {
    //                 Authorization: `Bearer ${codeResponse.access_token}`,
    //                 Accept: 'application/json'
    //               }
    //             })
    //           .then((res) => {
    //             // console.log('google log!',res.data.email);
    //             axios.post('https://chat-to-tender.vercel.app/api/googlelogin',{user:res.data.email})
    //             .then(response => {
    //               // setLoading(false);
    //               // console.log('response',response)
    //               setUserSession(response.data.token,response.data.user);
    //               history.push('/market');
    //               setError('Дякуємо!')
    //             }).catch(error => {
    //               // setLoading(false);
    //               setError('Помилка')
    //             });
    //           })
    //           .catch((err) => console.log('err'));

    //     },
    //     onError: (error) => console.log('Login Failed:', 'error')
    // });
    


  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const [error, setError] = useState('');
  

  const handleLogin = () => {
    
    if(username.value==""){
      setError('Введіть пошту')
      return
    }
    if(username.value.includes('.ru')){
      setError('Русні не можна')
      return
    }
    if(!username.value.includes('@')){
      setError('Невалідна адреса')
      return
    }
    // setError(null);
    setLoading(true);
    axios.post('https://chat-to-tender.vercel.app/api/register',{user:username.value})
    .then(response => {
      setLoading(false);
      // console.log('response',response)
      setError('Будь ласка, завершіть вхід через пошту')
    }).catch(error => {
      setLoading(false);
      setError('Помилка')
    });
  }



  // console.log("🚀 ~")
  // a.gribanovskiy@gmail.com

  return (
    <>
     <Header/>
        <Spacer h={6}/>

        <Grid.Container justify="center">
          <Grid xs={23} sm={12} md={5} style={{background:'#eee', border:'1px solid #ccc',padding:'20px'}} direction="column" alignItems="center">
        
              <Text h3>Увійти за допомогою пошти</Text>
              
              <Input type="secondary" width='100%' status="secondary" placeholder="Введіть ваш email" {...username} />
              <Spacer h={1}/>
              <Button width='100%'  type="secondary" onClick={handleLogin} disabled={loading} >{loading ? 'Loading...' : 'Увійти'}</Button>
              <Spacer h={1}/>
              <Text small stype="warning">{error}</Text>  
      
              <Divider style={{ backgroundColor: theme.palette.background }} width="100%"></Divider>
              {/* <Text small style={{textAlign:'center'}}>Скоро ми подамо інші способи авторизації...</Text> */}
              
              <Text>або</Text>
              {/* <GoogleLoginButton style={{fontSize:"14px"}} onClick={() => googleLogin()}/> */}
      
              <GoogleLogin
                onSuccess={credentialResponse => {
                  // console.log(credentialResponse);
                  const decoded = jwtDecode(credentialResponse.credential);
                  // console.log(decoded);

                  axios.post('https://chat-to-tender.vercel.app/api/googlelogin',{user:decoded.email})
                  .then(response => {
                  
                    setLoading(false);
                    // console.log('response',response)
                    setUserSession(response.data.token,response.data.user);
                    history.push('/market');
                    setError('Дякуємо!')
                  }).catch(error => {
                    // setLoading(false);
                    setError('Помилка')
                  });
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />

              <Spacer h={2}/>
        
        
              <Text small>Реєструючись, ви приймаєте <a href="/ugoda" >умови надання послуг</a></Text>
              
              <Text small>Повернутися на <a href="/" >головну</a></Text>
        

          </Grid> 
        </Grid.Container>
        <Spacer h={6}/>
        
     
    
    </>
  );
}


const useFormInput = initialValue => {
  
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Register;