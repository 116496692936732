import React from 'react';
import axios from 'axios';
import { Grid,Card, Image,Note,Spacer,Text,Divider,Loading,Link
,Tooltip,Button} from '@geist-ui/core'
import { NavLink } from 'react-router-dom';
import { ArrowRightCircle } from '@geist-ui/icons'
import Award from '@geist-ui/icons/award'
import MapPin from '@geist-ui/icons/mapPin'
// import { HashLink } from 'react-router-hash-link';
// import { QuestionCircle } from '@geist-ui/icons'
// import cover from './img/cover.jpg';
import Header from './Header';
import { TypeAnimation } from 'react-type-animation';
// import { Typewriter } from 'react-simple-typewriter'
// import robot from './img/robot.png';
import Footer from './Footer';




let test = {
  // padding:'5px',
  // background:'#ddd',
  // border:'1px solid #ddd'
}
function Home() {

  const [type1, setType1] = React.useState(false);
  const [type2, setType2] = React.useState(false);
  const [type3, setType3] = React.useState(false);


  const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';

  // console.log('!', type1)

  return (
    <>
      <Header/>
            
      {/* <TypeAnimation
      cursor={false}
      style={{
        fontSize: '1.75rem',
      }}
      className={CURSOR_CLASS_NAME}
      sequence={[
        'One',
        800,
        'One Two',
        800,
        'Three',
        (el) => el.classList.remove(CURSOR_CLASS_NAME), // A reference to the element gets passed as the first argument of a callback function
        2000,
        (el) => el.classList.add(CURSOR_CLASS_NAME),
        '',
      ]}
      repeat={Infinity}
    /> */}
    
    
    <style jsx="true">{`
      .custom-type-animation-cursor::after {
        content: '|';
        animation: cursor 1.1s infinite step-start;
      }
      @keyframes cursor {
        50% {
          opacity: 0;
        }
      }
    `}</style>
    
    {/* <Spacer h={1}/> */}
          
          
        <div style={{
       "background": "rgb(21,122,220)",
       "background": "radial-gradient(circle, rgba(21,122,220,.7) 0%, rgba(63,212,155,1) 100%)",minHeight:'400px'}}>
        
        <Spacer h={2}/>
        
        <Grid.Container  justify="center">
        {/* <Grid xs={23} sm={23} md={1} justify="center" direction="column">
          <Image width={"40px"} src={robot} />
        </Grid> */}
        <Grid xs={20} sm={20} md={8} justify="center" direction="column" style={test}>
        <Card type="violet" width="100%" style={{padding:'10px',paddingRight:'30px'}}>
        <TypeAnimation
          cursor={false}
          className={CURSOR_CLASS_NAME}
          sequence={[
            'Привіт, я читаю тендерну документацію і аналізую дані Prozorro. Для старту мені потрібно лише питання чи посилання на тендер',
              (el) => el.classList.remove(CURSOR_CLASS_NAME),
              () => {setType1(true)},
          ]}
          speed={70}
          style={{ fontSize: '1em' }}
          // repeat={Infinity}
        />
        </Card>
        </Grid>
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      <Grid.Container  justify="center"  style={test}>
        <Grid xs={20} sm={20} md={6} justify="center" direction="column">
        
        </Grid>
        <Grid xs={20} sm={20} md={4} justify="center" direction="column">
        {type1&&<div style={{border:"1px solid #333"}}>
          <Card width="100%">
            <TypeAnimation
              cursor={false}
              sequence={[
              500,(el) => el.classList.add(CURSOR_CLASS_NAME),'UA-2023-09-05-011599-a',(el) => el.classList.remove(CURSOR_CLASS_NAME)              
              ]}
              speed={70}
              style={{ fontSize: '1em' }}
            // repeat={Infinity}
            />
        </Card>
          </div>}
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      <Grid.Container  justify="center"  style={test} >
        <Grid xs={20} sm={20} md={10} justify="center" direction="column">
        </Grid>
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
          {/* <Card width="100%"> */}
            <TypeAnimation
              cursor={false}
              sequence={[
                7000,(el) => el.classList.add(CURSOR_CLASS_NAME),'До якої дати необхідно поставити товар?',(el) => el.classList.remove(CURSOR_CLASS_NAME),
              () => {setType2(true)}
              ]}
              speed={70}
              style={{ fontSize: '1em' }}
              // repeat={Infinity}
            />
          {/* </Card> */}
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      <Grid.Container  justify="center" style={test} >
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
        {type2&&<Card type="violet" width="100%" style={{padding:'10px'}}>
        <TypeAnimation
            cursor={false}
            sequence={[
              500,(el) => el.classList.add(CURSOR_CLASS_NAME),'До 09.10.2023 включно.',(el) => el.classList.remove(CURSOR_CLASS_NAME)
            ]}
            speed={70}
            style={{ fontSize: '1em' }}
            // repeat={Infinity}
          />
           </Card>
          }
        </Grid>
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
        </Grid>
      </Grid.Container>


      {/* <Grid.Container  justify="center"  style={test} >
        <Grid xs={23} sm={23} md={10} justify="center" direction="column">
        </Grid>
        <Grid xs={23} sm={23} md={8} justify="center" direction="column">
        <TypeAnimation
          cursor={false}
          sequence={[
            11000,(el) => el.classList.add(CURSOR_CLASS_NAME),'Чи може покупець в односторонньому порядку розірвати договір?',(el) => el.classList.remove(CURSOR_CLASS_NAME),
          () => {setType3(true)}
          ]}
          speed={70}
          style={{ fontSize: '1em' }}
        />
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      <Grid.Container  justify="center"  style={test} >
        <Grid xs={23} sm={23} md={8} justify="center" direction="column">
        {type3&&<Card type="violet" width="100%" style={{padding:'10px',paddingRight:'30px'}}>
        <TypeAnimation
          cursor={false}
          sequence={[
            500,(el) => el.classList.add(CURSOR_CLASS_NAME),"Так, покупець може в односторонньому порядку розірвати договір...і повідомити про це постачальника офіційним листом (електронною поштою) у строк не менше як за 10 календарних днів до дати розірвання договору.",(el) => el.classList.remove(CURSOR_CLASS_NAME)
          ]}
          speed={75}
          style={{ fontSize: '1em' }}
        />
        </Card>
        }
        </Grid>
        <Grid xs={23} sm={23} md={8} justify="center" direction="column">
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/> */}
      
      
      <Grid.Container  justify="center"  style={test} >
        <Grid xs={20} sm={20} md={10} justify="center" direction="column">
        </Grid>
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
        {/* <Card width="100%"> */}
        <TypeAnimation
          cursor={false}
          sequence={[
            11000,(el) => el.classList.add(CURSOR_CLASS_NAME),'Хто поточний постачальник замовника?',(el) => el.classList.remove(CURSOR_CLASS_NAME),
          () => {setType3(true)}
          ]}
          speed={70}
          style={{ fontSize: '1em' }}
          // repeat={Infinity}
        />
        {/* </Card> */}
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      <Grid.Container  justify="center"  style={test} >
        {/* <Grid xs={23} sm={23} md={1} justify="center" direction="column">
          <Image width={"40px"} src={robot} />
        </Grid> */}
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
        {type3&&<Card type="violet" width="100%" style={{padding:'10px',paddingRight:'30px'}}>
        <TypeAnimation
          cursor={false}
          sequence={[
            500,(el) => el.classList.add(CURSOR_CLASS_NAME),'Останній активний контракт був підписаний із компанією Український папір"...',(el) => el.classList.remove(CURSOR_CLASS_NAME)
          ]}
          speed={75}
          style={{ fontSize: '1em' }}
          // repeat={Infinity}
        />
        </Card>
        }
        </Grid>
        <Grid xs={20} sm={20} md={8} justify="center" direction="column">
        </Grid>
      </Grid.Container>
      
      <Spacer h={4}/>
      
        </div>
        
        
        
        
      
      
      
      <Spacer h={4}/>
      
           
      <Grid.Container  justify="center"  style={test}>
        <Grid xs={22} sm={22} md={12} justify="center" direction="column">
          <Note label={false} type="success"><Text>Декілька порад користувачам. Але пам’ятайте, що ChatGPT може надавати неточну інформацію про людей, місця чи факти!
          </Text></Note>
        </Grid>
      </Grid.Container>
      
      <Spacer h={1}/>
      
      <Grid.Container  justify="center">
      
        <Grid xs={22} sm={22} md={6} justify="center" direction="column" style={{margin:'20px'}}>
          <Card shadow style={{paddingRight:'30px', color:'#333'}}>
            <Text h4 my={0}>
            Більш конкретний запит дозволить отримати більш релевантну відповідь. 
            </Text>
            <Text>
            Якщо вас цікавить інформаія про ринок, то ви можете просто написати "пальне" (бот здогадається, що мова йде про cpv-клас 0913), проте краще одразу вказати конкретний cpv-код. Це ж стосується і учасників ринку. Ось приклад запиту: "Назви 3 найбільших постачальників пального в Рівненській області за сумою угод в 2023 році." 
            </Text>
          </Card>
        </Grid>
        <Grid xs={22} sm={22} md={6} justify="center" direction="column" style={{margin:'20px'}}>
          <Card shadow style={{paddingRight:'30px', color:'#333'}}>
            <Text h4 my={0}>
            В залежності від специфіку закупівель формулювання можуть різнитися. 
            </Text>
            <Text>
            Якщо ви постачальнки продуктів харчування і звертаєтеся до Тендерної документації конкретного тендеру, то є сенс спитати, "який строк придатності продуктів повинен бути". Або якщо тендер на надання послуг, то в питання треба питати саме про послугу (а не товар, як в прикладах вище). 
            </Text>
          </Card>
        </Grid>
        <Grid xs={22} sm={22} md={6} justify="center" direction="column" style={{margin:'20px'}}>
          <Card shadow style={{paddingRight:'30px', color:'#333'}}>
            <Text h4 my={0}>
            Враховуйте контекст документа, до якого звертаєтеся. 
            </Text>
            <Text>
            Договір може бути розірваний за купи причин (і непереборна сила і за згодою сторін і т.п.). Тому немає сенсу питати, чи може бути розірваний договір. Краще спитати, "Чи може бути достроково розірваний однією із сторін в односторонньому порядку".
            </Text>
          </Card>
        </Grid>
       </Grid.Container>
       
       
    

      <Spacer h={4}/>

      <Grid.Container  justify="center"  style={test} >
        <Grid xs={8} sm={8} md={2} justify="center" direction="column">
          <NavLink activeClassName="active" to="/register" >
            <Button style={{textTransform: 'uppercase',fontFamily:"'Roboto', sans-serif"}} type="secondary" auto >Спробувати!</Button>
          </NavLink>
        </Grid>
       </Grid.Container>

       <Spacer h={4}/>
      
       <Divider h={1}/>
       
       <Grid.Container justify="center" alignItems="center"  style={{background:'#fff'}}> 
         <Grid xs={22} sm={7} md={5} style={{marginTop:'20px',padding:'10px'}}>
           <Link target="_blank" href="https://courses.kse.ua/home/pp-specialist">
              <Card style={{opacity:.7}}>
                <Image src="https://cep.kse.ua/assets/img/prog2.png"
                 draggable={false} />
                <Text h4 mb={0}>"Фахівець з публічних закупівель"</Text>
                <Text type="secondary" small>*в умовах воєнного часу</Text>
              </Card>
            </Link>
          </Grid>   
          <Grid xs={22} sm={7} md={5} style={{marginTop:'20px',padding:'10px'}}>
            <Link target="_blank" href="https://courses.kse.ua/home/procurement-management">
              <Card style={{opacity:.7}}>
                <Image src="https://cep.kse.ua/assets/img/prog1.png"
                  draggable={false} />
                <Text h4 mb={0}>"Закупівельний менеджмент"</Text>
                <Text type="secondary" small>*в умовах воєнного часу</Text>
              </Card>
            </Link>
          </Grid>
      </Grid.Container>
      <Footer />
        
    </>
  );
}

export default Home;
