import React from 'react';
import { getUser,getToken } from './Utils/Common';
import { Grid,Card,Spinner,Link,Button, ButtonDropdown,useMediaQuery,Loading,Text,Spacer,Image,Toggle,Collapse,Input } from '@geist-ui/core'
import axios from 'axios';
// import Header from './Header';
import { NavLink,useHistory } from 'react-router-dom';

// import Usage from './Usage';
// import API from './API';
import robot from './img/robot.png';

import Dashboard from './Dashboard';
import { ArrowLeft } from '@geist-ui/icons'
import { ArrowRight } from '@geist-ui/icons'
// import ArrowDown from '@geist-ui/icons/arrowDown'
// import ExternalLink from '@geist-ui/icons/externalLink'
// import Trash2 from '@geist-ui/icons/trash2'
// import { NavLink } from 'react-router-dom';

let p_wrap = {
  "whiteSpace": "pre-line",
  fontWeight:'normal',
  fontSize:"16px",
  lineHeight:1.5,
  color:'#8000FF'
}


let test = {
  // 'border':'1px solid red'
}
// http://localhost:5000
// https://chat-to-tender.vercel.app

function Docs() {

  const user = getUser();
  const token = getToken();
  
  const isXS = useMediaQuery('xs')
  const [open, setOpen] = React.useState(0)

  const [spinner, setSpinner] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [tender, setTender] = React.useState("")
  const [vector, setVector] = React.useState(false)
  // const [docs, setDocs] = React.useState(false)
  const [question, setQuestion] = React.useState("")
  const [answer, setAnswer] = React.useState(null)
  const [context, setContext] = React.useState("")
  const [usagetender, setUsagetender] = React.useState(0)

  // https://api-chat-to-tender.vercel.app
  // http://localhost:3000
  
  const handleSubmit = event => {
    setVector(false)
    setAnswer(null)
    setContext("")
    setQuestion("")
    event.preventDefault()
    setSpinner(true)
    try {
      axios.get(`https://api-chat-to-tender.vercel.app/api/tenderdocs?tender=${tender}&user=${user}`)
      .then(response => {
        setVector(response.data)
        // console.log('setVector',response.data)
        setSpinner(false)
        // usage_this_month()
       })
       .catch((error) =>{
        setAnswer('Шось пішло не так. Можливо ТД не у форматі ворд-документів?')
       })
      } catch (error) {}
  }
  

  
  const handleSubmitQa = event => {
    event.preventDefault()
    setLoading(true)
    setAnswer(null)
    setContext("")
    getAnswer()
  }
  
  
  const getAnswer = () => {
    try {
      axios.get(`https://api-chat-to-tender.vercel.app/api/chatdocs?question=${question}&tender=${tender}&user=${user}`)
      .then(response => {
        // console.log(response.data)
        setContext(response.data.context)
        if(response.data.context.length==0)
        {
          // setLoading(true)
          console.log('!!!!! TWICE')
          getAnswer()
        }else{
          setAnswer(response.data.answer)
          setLoading(false)
        }
       })
       .catch((error) =>{
        setAnswer('Нажаль, чат неспрацював :( Спробуйте ще раз')
       })
    } catch (error) {
      setAnswer('Нажаль, чат неспрацював. Спробуйте ще раз')
    }
  }
  
  
  // React.useEffect(() => {
  //   if (answer){
  //     try {
  //       axios.get(`https://chat-to-tender.vercel.app/api/add_usage_question?user=${user}`)
  //       .then(response => {})    
  //     } catch (error) {}
  //   }
  // }, [answer]);

  
  
  // console.log('Docs:')

  return (
    <>
     
     
     {isXS&&<div style={{position:'absolute',top:'4%',left:'1%'}}>
        {open>0?<ArrowLeft color="#fff" className="hover" onClick={()=>setOpen(0)}/>:
        <ArrowRight className="hover" onClick={()=>setOpen(24)}/>}
     </div>}
     
     
     
     <Grid.Container  justify="center" >
        <Grid xs={open} sm={8} md={5} direction="column" style={{background:'#111'}}>    
          <Dashboard />
        </Grid>
        
        <Grid xs={24-open} sm={16} md={19}  direction="column" style={{background:'#fff'}}>  
  
        <div style={{
       "background": "rgb(21,122,220)",
       "background": "radial-gradient(circle, rgba(21,122,220,.7) 0%, rgba(63,212,155,1) 100%)"       
}}>
    
    <Spacer h={1}/>
          <Grid.Container justify="center">
          <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
          </Grid>
            <Grid xs={10} sm={12} md={18} style={test} direction="row" justify="center"> 
              <ButtonDropdown type="secondary">
                <ButtonDropdown.Item><NavLink to="/market" ><Text style={{color:'#000'}}>Про ринок</Text></NavLink></ButtonDropdown.Item>
                <ButtonDropdown.Item main><NavLink to="/docs" ><Text style={{color:'#fff'}}>Робота з документами</Text></NavLink></ButtonDropdown.Item>
                <ButtonDropdown.Item><NavLink to="/api" ><Text style={{color:'#000'}}>Документи по API</Text></NavLink></ButtonDropdown.Item>
              </ButtonDropdown>
            </Grid>
            <Grid xs={23} sm={23} md={4} justify="center" direction="column">    
            </Grid>
          </Grid.Container>
      <Spacer h={1}/>
      
      
      
      <Grid.Container  justify="center">
        <Grid xs={23} sm={23} md={2} justify="center" direction="column" style={test}>    
        </Grid>
        
        <Grid xs={23} sm={23} md={18} direction="column" style={test}>  
         
        <Text style={{color:'#4c2889'}}>Задавайте питання до Тендерної документації. Для цього спочатку вставте посилання на тендер, зачекайте поки завантажиться тендерна документація (якщо вона у форматі word-файлів), далі ви зможете задавати питання. 
        </Text>
        
        <Text small style={{color:'#4c2889'}}>Наприклад, "До якої дати необхідно поставити товар?", "Чи може замовник в односторонньому порядку розірвати договір?", "Де повинні знаходиться АЗС постачальника?" та "Коли оплата за товар?" чи "Чи необхідно мати сертифікати ISO?", тощо.
        </Text>
        </Grid>    
        <Grid xs={23} sm={23} md={4} justify="center" direction="column" style={test}>    
        </Grid>
      </Grid.Container>
      
      <Spacer h={3.8}/>
      
      </div>
      
      
      <Spacer h={3}/>
      
      {usagetender>=0&&
      <><Grid.Container  justify="center">
        <Grid xs={23} sm={23} md={2} direction="column">    
        </Grid>
        <Grid xs={23} sm={23} md={18} direction="column">
          <form onSubmit={handleSubmit}>
            <Text h3>Спочатку вставте посилання на тендер</Text>
            <Text small type="secondary">Наприклад, UA-2023-09-05-011599-a або UA-2023-11-17-010194-a або UA-2023-10-30-011212-a</Text>
            <Spacer h={.5}/>
            <Input scale={4/3} clearable type="secondary" style={{color: "#333"}} value={tender} onChange={(e)=>setTender(e.target.value)} iconRight={spinner&&<Spinner />} placeholder="UA-2023-09-05-011599-a" width="100%"/>
            <Spacer h={.5}/>
            {/* <Text small style={{color:docs?"#aaa":'#333'}}>Працювати із історією замовника і даними ринку</Text> <Toggle type="secondary" onChange={event => {setDocs(event.target.checked)}}/> <Text small style={{color:docs?"#333":'#aaa'}}> із документацією тендеру</Text> */}
          </form>
        </Grid>
        <Grid xs={23} sm={23} md={4} justify="center" direction="column" style={test}>    
        </Grid>
      </Grid.Container>
      <Spacer h={1}/>
      
      <Grid.Container  justify="center">
        <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
        </Grid>
        <Grid xs={23} sm={23} md={18} justify="center" direction="column">
          <form onSubmit={handleSubmitQa}>
            {vector? <>
            <Text h3>Тепер задавайте питання</Text>
            <Text small type="secondary">Наприклад, "До якої дати необхідно поставити товар?", "Чи може замовник в односторонньому порядку розірвати договір?", "Де повинні знаходиться АЗС постачальника?" та "Коли оплата за товар?" чи "Чи необхідно мати сертифікати ISO?", тощо.</Text>
            <Spacer h={.5}/>
            <Input scale={4/3} clearable type="secondary" style={{color: "#333"}} value={question} onChange={(e)=>setQuestion(e.target.value)} placeholder="Ваше питання до тендерної документації" width="100%"/></>:<Input scale={4/3} clearable type="secondary" disabled style={{color: "#333",opacity:.3}} value={question} onChange={(e)=>setQuestion(e.target.value)} placeholder="Ваше питання до тендерної документації" width="100%"/>}
          </form>
        </Grid>
        <Grid xs={23} sm={23} md={4} justify="center" direction="column" style={test}>    
        </Grid>
      </Grid.Container>
      
      <Spacer h={3}/>
      
      <Grid.Container  justify="center">
        <Grid xs={23} sm={23} md={2} justify="center" direction="column">
          {(loading||answer)&&<Image width={"40px"} src={robot} />}
        </Grid>
        <Grid xs={23} sm={23} md={18} justify="center" direction="column">
          {loading&&answer==null&&<Card width="100%" style={{paddingRight:'30px',border:'1px solid #4c2889',background:'#e0ecf4'}}><Loading color='#4c2889'/></Card>}
          {answer&&<Card width="100%" style={{paddingRight:'30px',border:'1px solid #4c2889',background:'#e0ecf4'}}><Text style={p_wrap}>{answer}</Text></Card>}
        </Grid>
        <Grid xs={23} sm={23} md={4} justify="center" direction="column" style={test}>    
        </Grid>
      </Grid.Container>
      
      <Spacer h={2}/>
      
      <Grid.Container  justify="center">
        <Grid xs={23} sm={23} md={2} justify="center" direction="column">    
        {/* <Text small style={{ color: '#aaa' }}>{context&&"КОНТЕКСТ"}</Text> */}
        </Grid>
        <Grid xs={23} sm={23} md={18} justify="center" direction="column"> 
          {context&&<Collapse.Group>
          <Collapse title={<Text style={{color:'#7928ca'}} font="14px">Контекст:</Text>}>
            <Text small style={{ color: '#aaa' }}>{context}</Text>
          </Collapse>
        </Collapse.Group>}
        </Grid>
        <Grid xs={23} sm={23} md={4} justify="center" direction="column" style={test}>    
        </Grid>
      </Grid.Container>
      
    
      </> }
      
      <Spacer h={2}/> 


    </Grid>

    
    </Grid.Container>
      
    </>
  );
}



export default Docs;
