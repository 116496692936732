import React from 'react';
// import { useLocation,useSearchParams} from 'react-router-dom';
import Header from './Header';
import { Grid,Divider, Spacer,Text } from '@geist-ui/core'


let test = {
// border:"1px solid grey"
lineHeight:1.5
}


function About() {
  
  // console.log('About:')


  return (
    <div className="App">
     <Header/>
      <Spacer h={2}/>
      

      <Grid.Container  justify="center" style={test}>
        <Grid xs={22} sm={18} md={14} style={test} direction="column">

        <Text h1>
        УГОДА КОРИСТУВАЧА
        </Text>
   
        
        <Spacer h={2}/>
<Divider style={{background:'#3fd4ad'}}>1. Умови використання веб-сайту</Divider>
<Spacer h={1}/>
        
<Text>
1.1. Ця угода користувача (надалі - Угода) є пропозицією щодо умов використання веб-сайту
(надалі - Сайт) та встановлює правила користування Сайтом для Адміністрації Сайту та
фізичних осіб (включаючи представників юридичних осіб) (надалі - Користувач), а також регламентує<br></br> 
умови надання Користувачем інформації для розміщення на Сайті.
1.2. Користувачем Сайту вважається будь-яка фізична особа, яка коли-небудь мала доступ до Сайту і
досягла віку, дозволеного для прийняття цієї Угоди.<br></br> 
1.3. Користувач зобов&#39;язаний повністю ознайомитися з цією Угодою до моменту реєстрації на Сайті.
Реєстрація Користувача на Сайті означає повне та беззастережне прийняття Користувачем цієї Угоди. У
разі незгоди з умовами Угоди користування Сайтом Користувач повинен негайно припинити
користуванням Сайтом.<br></br> 
1.4. Ця Угода може бути змінена та/або доповнена Адміністрацією Сайту в односторонньому порядку без
спеціального повідомлення. Ці Правила є відкритим та загальнодоступним документом.<br></br> 
1.5. Угода передбачає взаємні права та обов&#39;язки Користувача та Адміністрації Сайту.
</Text> 


<Spacer h={2}/>
<Divider style={{background:'#3fd4ad'}}>2. Порядок використання Сайту</Divider>
<Spacer h={1}/>

<Text p>2.1. Сайт дозволяє Користувачу, зокрема, але не виключно: коористуватися чат-ботом ChatGPT через офіційний API OpenAI.<br></br> 
2.2. ChatGPT може робити помилки. Перевірте важливу інформацію. Діють всі ті самі обмеження, що і при користуванні ботом на веб-ресурсі https://chat.openai.com/<br></br> 

</Text>


<Spacer h={2}/>
<Divider style={{background:'#3fd4ad'}}>3. Особиста інформація Користувача</Divider>
<Spacer h={1}/>

<Text p>3.1. Адміністрація сайту ставиться з повагою та відповідальністю до конфіденційної інформації будь-якої
особи, яка відвідує цей сайт. Приймаючи цю Угоду, Користувач надає згоду на збір, обробку та

використання певної інформації про себе відповідно до положень Закону України &quot;Про захист
персональних даних&quot; та Правил Адміністрації сайту щодо захисту персональних даних. Крім того,
Користувач надає згоду на те, що Адміністрація сайту може збирати, використовувати, передавати,
обробляти та зберігати інформацію, пов&#39;язану з обліковим записом Користувача, з метою надання
відповідних послуг.<br></br> 
3.2. Користувач Сайту, розміщуючи резюме та/або інші дані на Сайті та/або відсилаючи резюме та/або
інші подібні листи на електронні адреси компаній та/або інших користувачів Сайту, добровільно надає
Адміністрації сайту - ГРОМАДСЬКІЙ ОРГАНІЗАЦІЇ «ІНСТИТУТ КИЇВСЬКА ШКОЛА ЕКОНОМІКИ», юридична
адреса: 03113, Україна, м. Київ, вул. Миколи Шпака, буд. 3, а також третім особам, які мають доступ
до цього Сайту, свою згоду на обробку своїх персональних даних (які містяться в його резюме,
повідомленнях та/або іншій інформації, зібраній та/або розміщуваній на Сайті та/або в інформації, яку
він відсилає іншим Користувачам Сайту).<br></br> 
3.3. Обробка Персональних даних включає в себе будь-які дії та/або сукупність дій, які пов’язані
зі збором, реєстрацією, накопиченням, зберіганням, адаптацією, зміною, поновленням, використанням
та поширенням (реалізацією, передачею), видаленням Персональних даних Користувача з метою
забезпечення роботи сервісів Сайту.<br></br> 
3.4. Обсяг Персональних даних Користувача, по відношенню до яких здійснюється обробка і які можуть
бути включені в бази персональних даних (у тому числі в базу персональних даних резюме),
визначається як будь-яка інформація про Користувача, (яка розміщена в його резюме та/або інших
даних, зібраних та/або розміщених на Сайті та/або листах надісланих з його допомогою), яка стане
відома ГРОМАДСЬКІЙ ОРГАНІЗАЦІЇ «ІНСТИТУТ КИЇВСЬКА ШКОЛА ЕКОНОМІКИ», а також будь-яким третім
особам, які використовують Сайт.<br></br> 
3.5. Згода Користувача на обробку його персональних даних не вимагає від ГРОМАДСЬКОЇ ОРГАНІЗАЦІЇ
«ІНСТИТУТ КИЇВСЬКА ШКОЛА ЕКОНОМІКИ» додаткових повідомлень при передачі його персональних
даних третім особам відповідно до норм ст. 21 Закону України «Про захист персональних даних».<br></br> 
3.6. Приймаючи дану угоду, користувач підтверджує, що йому зрозумілі його права, визначені Законом
України «Про захист персональних даних», а також мета збору, зберігання і обробки його персональних
даних. Користувач також дає згоду, що термін обробки його персональних даних є безстроковим.<br></br> 
3.7. Користувач може в будь-який момент змінити (оновити, доповнити) надані їм персональні дані або
їх частину, а також параметри їх конфіденційності, скориставшись функцією редагування
та налаштування доступу до особистої інформації, а також вимагати від Компанії повного видалення його
аккаунту і всіх персональних даних з бази даних Сайту.<br></br> 
3.8. Адміністрація сайту збирає як основні персональні дані, такі як ім&#39;я, прізвище, по батькові, адреса та
електронна адреса, так і вторинні (технічні) дані - файли cookies, інформація про з&#39;єднання та системна
інформація.<br></br> 
3.9. Користувач згоден з тим, що конфіденційність переданих через Інтернет даних не гарантується у разі,
якщо доступ до цих даних отримують треті особи поза зоною технічних засобів зв&#39;язку, що підпадають
під контроль Адміністрації сайту. Адміністрація сайту не несе відповідальності за шкоду, заподіяну таким
доступом.<br></br> 
3.10. Адміністрація сайту може використовувати будь-яку зібрану через Сайт інформацію з метою
поліпшення вмісту інтернет-сайту, його вдосконалення, передачі інформації Користувачу (за запитами),
для маркетингових або дослідницьких цілей, а також для інших цілей, що не суперечать положенням
чинного законодавства України.
 </Text>

 <Spacer h={2}/>
<Divider style={{background:'#3fd4ad'}}>4. На Сайті заборонено:
</Divider>
<Spacer h={1}/>
<Text p>4.1. Заклики до насильницької зміни або зміни конституційного ладу, захоплення державної влади;
заклики до зміни адміністративних чи державних кордонів України, порушення порядку, встановленого
Конституцією України; заклики до розгрому, підпалу, руйнування майна, захоплення будівель чи споруд,
насильницького виселення громадян; заклики до агресії або розпалення військового конфлікту;
розміщувати інформацію, що закликає до порушення територіальної цілісності України; визнання
незаконних адміністративно-територіальних утворень на території України, що містить назви, символіку,
пропаганду сепаратистських чи терористичних організацій.
<br></br> 
4.2. Прямі і непрямі образи будь-кого, зокрема політиків, посадових осіб, журналістів, користувачів
ресурсу, включаючи образи за національною, етнічною, расовою або релігійною приналежністю, а також
шовіністичні висловлення.<br></br> 
4.3. Ненормативна лексика, матеріали порнографічного, еротичного або сексуального характеру.<br></br> 
4.4. Будь-яка образлива поведінка стосовно авторів статей та всіх учасників ресурсу.<br></br> 
4.5. Висловлювання, метою яких є намагання провокувати різку реакцію інших учасників ресурсу.<br></br> 
4.6. Реклама, комерційні повідомлення, а також повідомлення, які не несуть інформаційного
навантаження і не стосуються тематики ресурсу, якщо на таку рекламу чи повідомлення не отримано
спеціального дозволу від Адміністрації сайту.
4.7. Будь-які повідомлення та інші дії, заборонені законодавством України.<br></br> 
4.8. Представлення себе за іншу людину або представника організації і/або спільноти без належних прав,
включаючи співробітників та власників Сайту, а також введення в оману щодо властивостей і
характеристик будь-яких суб&#39;єктів чи об&#39;єктів.<br></br> 
4.9. Розміщення матеріалів, доступ до яких Користувач не має права надавати згідно з законом чи
контрактними відносинами, а також матеріалів, які порушують права на будь-який патент, торговельну
марку, комерційну таємницю, авторське право або інші права власності і/або авторські та суміжні права
третьої сторони.<br></br> 
4.10. Розміщення несанкціонованої рекламної інформації, спаму, схем &quot;пірамід&quot;, &quot;листів щастя&quot;;
матеріалів, що містять комп&#39;ютерні коди, призначені для порушення, знищення або обмеження
функціональності будь-якого комп&#39;ютерного або телекомунікаційного обладнання чи програм, для
здійснення несанкціонованого доступу, а також серійних номерів для комерційних програмних
продуктів, логінів, паролів та інших засобів для отримання несанкціонованого доступу до платних
ресурсів в Інтернеті.<br></br> 
4.11. Навмисне або випадкове порушення будь-яких чинних місцевих, державних або міжнародних
нормативно-правових актів.
</Text>


<Spacer h={2}/>
<Divider style={{background:'#3fd4ad'}}>5. Обмеження відповідальності Адміністрації сайту</Divider>
<Spacer h={1}/>

<Text p>5.1. Адміністрація сайту не несе відповідальності за будь-які помилки, друкарські помилки та неточності,
які можуть бути виявлені в матеріалах, що містяться на даному Сайті. Адміністрація сайту докладає всі
необхідні зусилля для забезпечення точності та достовірності інформації, яка надається на Сайті.<br></br> 
5.2. Інформація на Сайті постійно оновлюється і в будь-який момент може стати застарілою.
Адміністрація сайту не несе відповідальності за отримання застарілої інформації з Сайту, а також за
нездатність Користувача отримати оновлення збереженої на Сайті інформації.<br></br> 
5.3. Адміністрація сайту не несе відповідальності за висловлення та думки відвідувачів сайту, залишені у
вигляді коментарів чи оглядів. Погляди Адміністрації сайту можуть не збігатися з поглядами та позицією
авторів оглядів та коментарів. У той же час Адміністрація сайту робить всі можливі заходи, щоб не
допустити публікацію повідомлень, які порушують діюче законодавство або норми моралі.<br></br> 
5.4. Адміністрація сайту не несе відповідальності за можливі незаконні дії Користувача стосовно третіх
осіб, а також за незаконні дії третіх осіб стосовно Користувача.<br></br> 
5.5. Адміністрація сайту не несе відповідальності за висловлення Користувача, розміщені або
опубліковані на Сайті.<br></br> 
5.6. Адміністрація сайту не несе відповідальності за збитки, втрати чи витрати (фактичні чи можливі), що
виникли в зв&#39;язку із цим Сайтом, його використанням або неможливістю використання.<br></br> 
5.7. Адміністрація сайту не несе відповідальності за втрату Користувачем можливості доступу до свого
облікового запису на Сайті.<br></br> 
5.8. Адміністрація сайту не несе відповідальності за неповні, неточні, некоректні дані, які Користувач
надав при створенні свого облікового запису.<br></br> 
5.9. У випадку виникнення проблем з використанням Сайту, незгоди з конкретними розділами
Положення Користувача, отриманням Користувачем недостовірної інформації від третіх осіб або
образливої інформації, будь ласка, звертайтеся до адміністрації Сайту, щоб Адміністрація могла
проаналізувати та виправити відповідні недоліки, обмежити і запобігти надходженню небажаної

інформації на Сайт, а також, за необхідності, обмежити або припинити виконання своїх зобов&#39;язань
перед ним будь-якому Користувачу та клієнту, умисно порушуючому Угоду користувача та роботу Сайту.<br></br> 
5.10. З метою вищевикладеного Адміністрація сайту залишає за собою право видаляти розміщену на
Сайті інформацію та здійснювати технічні та юридичні заходи для припинення доступу Користувачів, які,
на думку Адміністрації сайту, створюють проблеми використання Сайту іншими Користувачами, або
Користувачів, які порушують вимоги Положення.
</Text>

<Spacer h={2}/>
<Divider style={{background:'#3fd4ad'}}>Порядок дії Угоди
</Divider>
<Spacer h={1}/>
<Text p>
6.1. Ця Угода є договором. Адміністрація сайту залишає за собою право як змінити цю Угоду, так і ввести
нову. Такі зміни набувають чинності з моменту їх розміщення на Сайті. Використанням Користувачем<br></br> 
матеріалів Сайту після змін Угоди автоматично підтверджується їх прийняття.
6.2. Ця Угода набуває чинності при першому відвідуванні Сайту Користувачем і діє між Користувачем і
Адміністрацією сайту на протязі всього періоду використання Користувачем Сайту.<br></br> 
6.3. Сайт є об&#39;єктом права інтелектуальної власності Адміністрації сайту. Всі виняткові майнові авторські
права на сайт належать Адміністрації сайту. Використання сайту Користувачами можливе виключно в
рамках цієї Угоди та законодавства України про права інтелектуальної власності.<br></br> 
6.4. Усі торгові марки та назви, на які надаються посилання в матеріалах цього Сайту, є власністю їх
відповідних власників.<br></br> 
6.5. Користувач погоджується не відтворювати, не повторювати, не копіювати будь-які частини Сайту,
крім випадків, коли Адміністрація сайту надає на це дозвіл.<br></br> 
6.6. Ця Угода регулюється і тлумачиться відповідно до законодавства України. Питання, які не
врегульовані Угодою, підлягають вирішенню відповідно до законодавства України.
</Text>
        
<Spacer h={2}/>

<Text p>
Захист персональних даних
Шановний Користувач, реєструючись на нашому сайті https://cep.kse.ua/ або використовуючи різні
сервіси нашого сайту, які вимагають введення Ваших особистих даних, Ви надаєте згоду на обробку
своїх персональних даних відповідно до Закону України &quot;Про захист персональних даних&quot;, а також
згодом із затвердженими і узгодженими Правилами. Адміністрація сайту залишає за собою право
використовувати цю інформацію у маркетингових цілях в рамках надання послуг. Узгоджені Правила
відповідно до Закону України &quot;Про захист персональних даних&quot; Користувач, реєструючись на сайті
https://cep.kse.ua/ (далі - «Сайт»), включаючи створення свого облікового запису і/або реєстрацію як
користувача будь-яких послуг Сайту, за виконанням відповідних дій під своїм власним та дійсним, (а не
вигаданим) іменем, надає свою згоду власнику Сайту на обробку персональних даних Користувача (ПІБ,
стать, вік, місце проживання, дата і місце народження, контактні дані в разі надання такої
інформації і за умови, що така інформація є правдивою і дійсною). Користувач надає свою згоду
власнику Сайту на здійснення дій в інформаційній (автоматизованій) системі та/або в картотеках
персональних даних, які пов&#39;язані зі збором, реєстрацією, накопиченням, зберіганням, адаптацією,
зміною, оновленням, використанням та поширенням (реалізацією, передачею), анонімізацією,
знищенням відповідних персональних даних та відомостей про Користувача - фізичну особу.
Користувач також надає власнику Сайту право визначати на власний розсуд порядок використання
персональних даних Користувача (зокрема порядок використання персональних даних працівниками
власника Сайту відповідно до їх професійних, службових або трудових обов&#39;язків), порядок захисту,
поширення, порядок доступу до баз даних та порядок передачі права обробки персональних даних
іншими суб&#39;єктами відносин, пов&#39;язаних з персональними даними. Користувач надає вказані права
власнику Сайту з метою виконання останнім вимог чинного законодавства щодо захисту
персональних даних.
</Text>

        </Grid>
       
        </Grid.Container>


    </div>
  );
}

export default About;
