import React from 'react';
import { Grid,Description,useMediaQuery, Divider,Text,Spacer,Button,Link,Tooltip,Snippet } from '@geist-ui/core'
import axios from 'axios';
import { getUser,getToken, removeUserSession } from './Utils/Common';
// import Header from './Header';
import { NavLink,useHistory } from 'react-router-dom';
// import * as d3 from "d3";

// import Usage from './Usage';
// import Chat from './Chat';
// import Docs from './Docs';
// import API from './API';
// import robot from './img/robot.png';
import { UserX } from '@geist-ui/icons'

// import queryString from 'query-string';


let test = {
  // 'border':'1px solid red'
}

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

// http://localhost:5000/
// https://chat-to-tender.vercel.app

function Dashboard() {

  // const { setToast } = useToasts()
  // const click = () => console.log('!!!')
  
  const user = getUser();
  const token = getToken();
  
  const history = useHistory();

  const [loading, setLoading] = React.useState(true)
  const [new_questions, setNewQuestions] = React.useState([])
  const [old_questions, setOldQuestions] = React.useState([])


  function getdata(){
    try {
      axios.get(`https://chat-to-tender.vercel.app/api/my_questions?user=${user}&token=${token}`)
      .then(response => {
        response.data.forEach(e => {
          // e.date = d3.timeFormat('%Y-%m-%d')(new Date(e.date))
          e.date = new Date(e.date)
        })
        response.data.sort((a,b)=>b.date-a.date)
        setNewQuestions(response.data.filter(d=>d.date>=new Date().addDays(-1)))
        setOldQuestions(response.data.filter(d=>d.date<new Date().addDays(-1)))
        setLoading(false)
        // console.log('dash',response.data)
        })   
    } catch (error) {
      // setSpinner(false)
    }
  }


  React.useEffect(() => {
    let timer = setTimeout(() => getdata(), 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  // const isXS = useMediaQuery('xs')

  const handleLogout = () => {
    removeUserSession()
    history.push(`/register`);
  }
  
  // console.log('Dash:',new_questions,old_questions)

  return (
    <>
     
     <div style={{"background": "#111", borderRight:'1px solid #333',color:'#fff',height:'100vh',
    //  position: "absolute",
    //  width:'200px',
    // top: 0,
    // left: 0
    }}>
     

     <Spacer h={1}/>
      <Grid.Container  justify="center"  direction='row'>
              
        <Description title={user}/>
        <Spacer h={2}/>
        <Divider type="secondary"/>
        
        
        {new_questions.length&&new_questions.length==0?
        <>
          <Spacer h={16}/>
          <Text small style={{color:'grey'}}>Тут буде історія ваших питань!</Text>
          <Spacer h={14.15}/>
        </>:
        <Grid xs={23} sm={23} md={22} 
        className="scroll"
        
          direction='column'>    
          
          {/* style={{
                 width: "250px",
                 whiteSpace: "nowrap",
                 overflow: "hidden",
                 textOverflow: "ellipsis"
                 }}
                  */}
           <Text small style={{color:'#aaa', textAlign:'center',fontSize:'11px',padding:'5px'}}>За останню добу</Text>
            {new_questions.map((d,i) => (
            <div key={i} >
                <Snippet type="dark" symbol="" text={d.question}/>
              {/* <Spacer h={.5}/> */}
            </div>
            ))
          }   
 
          {old_questions.length>0&&
          <>
            <Text small style={{color:'#aaa', textAlign:'center',fontSize:'11px',padding:'5px'}}>Попередні дні</Text>
            {old_questions.map((d,i) => (
            <div key={i}>
              <Snippet type="dark" symbol="" text={d.question} />
              {/* <Spacer h={.5}/> */}
            </div>
            ))
            }
          </>
          }
          
        </Grid>
        
        }
        
      </Grid.Container>
      
      <Divider type="secondary"/>
      
      
        <Grid.Container  justify="center"  direction='row' style={{color:'#aaa'}}>
        <Grid xs={23} sm={23} md={22} justify="center" direction='column' style={test}>
        <Link href="/" underline><Text small>До головної</Text></Link>
        <Spacer h={.5}/>
        <Text small><Link href="/market" underline>Інформація про ринок</Link></Text>
          <Spacer h={.5}/>
          <Text small><Link href="/docs" underline> Інформація про документацію</Link></Text>
          <Spacer h={.5}/>
          <Text small><Link href="/api" underline>Наше API</Link></Text>
          <Spacer h={.5}/>
          <Button type="secondary" onClick={handleLogout} ghost scale={0.7} icon={<UserX />}>Вийти</Button> 
          
          <Spacer h={1}/>
          
        </Grid>
        
       
        
      </Grid.Container>
      
     


    </div>
      
              
    
            
    </>
  );
}



export default Dashboard;
